.hostApp {
    @import "./host-styles/main.chunk.css";
    @import "./host-styles/npm.sanitize.css.chunk.css";
    @import "./host-styles/npm.simplebar.chunk.css";
    @import "./host-styles/npm.tippy.js.chunk.css";

    padding-left: 10px;

    .line {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 620px;
    }

    .headSpace {
        margin-top: 40px;
    }

    .display {
        max-width: 800px;
    }

    button {
        width: 160px;
    }

    input {
        border: 1px solid;
        border-color: var(--color-darkgreen-500);
    }

    ul {
        font-size: 18px;
    }
}
